import { StateCreator, create } from 'zustand';
import { persist } from 'zustand/middleware';
import localStorageAvailable from '@helpers/localStorage';
import withHydrationHelpers from '@javascript/helpers/hydration';
import {
  CounterOperation,
  updateShortlistCount,
} from '@javascript/lib/requests/shortlist';
import { acMobileFeatures } from '@arnoldclark/ac-mobile-features';

import apiStorage from './shortlistApiStorage';
import syncTabs from './shortlistSyncTabs';
import { clearToast, openToast, ToastType } from '../application/toast';

export type StockReferences = string | string[];

export interface ShortlistStoreState {
  add: (stockRef: string) => void;
  addMany: (stockRefs: string[]) => void;
  remove: (stockRef: string) => void;
  replace: (shortlist: string[]) => void;
  reset: () => void;
  shortlist: string[];
  sold: string[];
}

const { isLoggedIn, shortlistEnabled, isNativeApp } = acMobileFeatures();

const hasAuth0Token =
  localStorageAvailable() &&
  Object.keys(localStorage).some((key) =>
    key.includes(window.authOptions.clientId)
  );

const usingApiStorage =
  (isLoggedIn() && shortlistEnabled()) || (!isNativeApp() && hasAuth0Token);

// checking for old format shortlists to migrate users
export const migratedShortlist = (): string[] | null => {
  if (localStorageAvailable()) {
    const cachedShortlist = localStorage.getItem('shortlist');

    if (typeof cachedShortlist !== 'string') return null;

    // handling empty strings to avoid zustand-persist breaking
    if (cachedShortlist.length === 0) {
      localStorage.removeItem('shortlist');
      return null;
    }

    if (cachedShortlist.length > 0) {
      try {
        // it's a zustand object, no further action required if not logged-in: zustand-persist will retrieve it on load
        const {
          state: { shortlist },
        } = JSON.parse(cachedShortlist);
        //logged-in users need any localStorage shortlist vehicles set as initialState to update API
        if (usingApiStorage) {
          //and then clear out localStorage so we don't end up constantly adding these vehicles to the savedShortlist
          localStorage.removeItem('shortlist');
          return shortlist;
        }
      } catch (_err) {
        // it's a string, and a shortlist that requires migration
        // convert to array and remove the old localStorage value
        // because when zustand-persist calls JSON.parse with it, it will fall over
        localStorage.removeItem('shortlist');
        return cachedShortlist.split(',');
      }
    }
  }

  return null;
};

const shortlistStoreCreator: StateCreator<ShortlistStoreState> = (
  set,
  get
) => ({
  shortlist: migratedShortlist() || [],
  sold: [],
  add: (stockRef) => {
    updateShortlistCount(stockRef, CounterOperation.increment);

    set({ shortlist: [...new Set([...get().shortlist, stockRef])] });

    openToast({
      additionalStyling: 'ac-toast--shortlist__added',
      link: { text: 'View', href: '/shortlist' },
      message: 'Added to your shortlist',
      toastType: ToastType.default,
    });
  },
  addMany: (stockRefs) => {
    updateShortlistCount(stockRefs, CounterOperation.increment);

    set({ shortlist: [...new Set([...get().shortlist, ...stockRefs])] });
  },
  remove: (stockRef) => {
    updateShortlistCount(stockRef, CounterOperation.decrement);

    set({
      shortlist: get().shortlist.filter(
        (shortlisted) => shortlisted !== stockRef
      ),
    });

    clearToast();
  },
  replace: (shortlist) => {
    set({ shortlist });
  },
  reset: () => {
    updateShortlistCount(get().shortlist, CounterOperation.decrement);

    set({ shortlist: [], sold: [] });
  },
});

const storeWithSyncedTabs = syncTabs(shortlistStoreCreator);

export const useShortlistStore = usingApiStorage
  ? create(apiStorage(storeWithSyncedTabs))
  : create(
      persist(storeWithSyncedTabs, {
        name: 'shortlist',
        skipHydration: true,
      })
    );

const useShortlist =
  withHydrationHelpers<ShortlistStoreState>(useShortlistStore);

export default useShortlist;
